import BigNumber from 'bignumber.js'
import { useEffect, useState } from 'react'

const NetworkSubgraphUrl =
    'https://gateway-arbitrum.network.thegraph.com/api/8bcbd55cdd1369cadb0bb813d9817776/subgraphs/id/EGWFdhhiWypDuz22Uy7b3F69E9MEkyfU9iAQMttkH5Rj'

async function q(url, { query, variables }) {
    const resp = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query,
            variables,
        }),
    })

    return resp.json()
}

/**
 * Fetched Sponsorships and calculates global APY and the number
 * of staked DATA tokens.
 */
async function getStakingStats() {
    const batchSize = 999

    let totalStakeBN = new BigNumber(0)

    let totalEarningStakeBN = new BigNumber(0)

    let totalYearlyPayoutBN = new BigNumber(0)

    let page = 0

    for (;;) {
        const {
            data: { sponsorships },
        } = await q(NetworkSubgraphUrl, {
            query: `
                query GetSponsorships($first: Int!, $skip: Int!) {
                    sponsorships(first: $first, skip: $skip, where: { totalStakedWei_gt: "0" }) {
                        isRunning
                        remainingWei
                        spotAPY
                        totalStakedWei
                    }
                }
            `,
            variables: {
                first: batchSize + 1,
                skip: page * batchSize,
            },
        })

        for (const { totalStakedWei, spotAPY, isRunning, remainingWei } of sponsorships) {
            const totalStakedWeiBN = new BigNumber(totalStakedWei)

            totalStakeBN = totalStakeBN.plus(totalStakedWeiBN)

            const remainingWeiBN = new BigNumber(remainingWei)

            if (isRunning && remainingWeiBN.gt(0)) {
                totalEarningStakeBN = totalEarningStakeBN.plus(totalStakedWeiBN)

                totalYearlyPayoutBN = totalYearlyPayoutBN.plus(
                    totalStakedWeiBN.multipliedBy(new BigNumber(spotAPY)),
                )
            }
        }

        if (sponsorships.length <= batchSize) {
            /**
             * Recent batch was the last batch.
             */
            break
        }

        page = page + 1
    }

    return {
        /**
         * APY is calculated using earning sponsorships. Total yearly payout made
         * by earning sponsorships divided by sum of earning stakes.
         */
        apy: totalYearlyPayoutBN.dividedBy(totalEarningStakeBN).multipliedBy(100).toNumber(),
        /**
         * TVL on the other hand is a sum of all staked tokens, earning or not,
         * which is more true after all.
         */
        tvl: totalStakeBN.dividedBy(10 ** 18).toNumber(),
    }
}

export function useGlobalApy() {
    const [apy, setApy] = useState()

    useEffect(function getApy() {
        let mounted = true

        void (async () => {
            try {
                const { apy: result } = await getStakingStats()

                if (mounted) {
                    setApy(result.toFixed(2))
                }
            } catch (e) {
                console.warn('Unable to calculate global average APY', e)

                if (mounted) {
                    setApy(null)
                }
            }
        })()

        return () => {
            mounted = false
        }
    }, [])

    return apy
}

export function useNetworkSummary() {
    const [summary, setSummary] = useState()

    useEffect(function fetchNetworkSummary() {
        let mounted = true

        void (async () => {
            try {
                const {
                    data: { summary },
                } = await q('https://stream-metrics.streamr.network/api', {
                    query: `
                        query GetNetworkSummary {
                            summary {
                                messagesPerSecond
                                nodeCount
                            }
                        }
                    `,
                })

                if (mounted) {
                    setSummary(summary)
                }
            } catch (e) {
                console.warn('Unable to fetch global node count', e)

                if (mounted) {
                    setSummary(null)
                }
            }
        })()

        return () => {
            mounted = false
        }
    }, [])

    return summary
}
